import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href='https://www.linkedin.com/in/bryan-pho-41b1a2127' rel='noreferrer' target='_blank'><BsLinkedin /></a>
        <a href='https://github.com/phobv' rel='noreferrer' target='_blank'><BsGithub /></a>
    </div>
  )
}

export default HeaderSocials