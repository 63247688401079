import React from 'react'
import './services.css'
import {BsCheckLg} from 'react-icons/bs'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Simple and clean layouts.</p>
            </li>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Use of Complementary colors.</p>
            </li>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Icons used are always high quality.</p>
            </li>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>User friendly experience.</p>
            </li>
          </ul>
        </article>
        <article className='service'>
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Clean and readable code.</p>
            </li>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Clear communication.</p>
            </li>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Responsive webpages.</p>
            </li>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>HTML, CSS, JavaScript knowledge.</p>
            </li>
          </ul>
        </article>
        <article className='service'>
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Custom logos through Photoshop.</p>
            </li>
            <li>
              <BsCheckLg className='service_list-icon' />
              <p>Add/remove features from photos.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services