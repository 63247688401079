import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fleocdb', 'template_obyzodd', form.current, 'Z5f6c2juVvfSzU7kv')
    
    e.target.reset();
  };

  return (
   <section id='contact'>
     <h5>Get In Touch</h5>
     <h2>Contact Me</h2>

     <div className="container contact__container">
       <div className="contact__options">
        <article className="contact__option">
          <MdOutlineEmail className='contact-icon'/>
          <h4>Email</h4>
          <h5>pho.bryan@gmail.com</h5>
          <a href="mailto:pho.bryan@gmail.com" target='_blank'>Send a message</a>
        </article>
        <article className="contact__option">
          <RiMessengerLine className='contact-icon'/>
          <h4>Messenger</h4>
          <h5>Bryan Pho</h5>
          <a href="https://m.me/pho.bryan" target='_blank'>Send a message</a>
        </article>
       </div>
       {/* End Of Contact Options */}
       <form ref={form} onSubmit={sendEmail}>
         <input type="text" name='name' placeholder='Your Full Name' required />
         <input type="email" name='email' placeholder='Email' required />
         <textarea name="message" rows="7" placeholder='Your message' required></textarea>
         <button type='submit' className='btn btn-primary'>Send Message</button>
       </form>
     </div>
   </section>
  )
}

export default Contact
