import React from 'react'
import './about.css'
import ME from '../../assets/Bike.PNG'
import {FaAward} from 'react-icons/fa'
import {FiUser} from 'react-icons/fi'
import {GiGraduateCap} from 'react-icons/gi'



const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
      <div className="about__me">
        <div className='about__me-img'>
          <img className='about-img' src={ME} alt="About Image" />
        </div>
      </div>
      
      <div className="about__content">
        <div className="about__cards">
          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>3+ Years Working</small>
          </article>

          <article className='about__card'>
            <FiUser className='about__icon'/>
            <h5>Clients</h5>
            <small>200+ Clients Helped</small>
          </article>

          <article className='about__card'>
            <GiGraduateCap className='about__icon'/>
            <h5>Education</h5>
            <small>Studied at VCU</small>
          </article>
        </div>

        <p>
          When I was younger, I was always intrigued with how things were put togther and how they worked. This eventually developed into my curiosity with software and then programming. When I am not learning new things in the evolving tech world, I enjoy spending time with friends and finding new activities to do. Mountain biking and gaming are also some of the many things I like to do during my free time.
        </p>
        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
      </div>
      </div>
    </section>
  )
}

export default About

